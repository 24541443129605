import ApiService from "@/core/services/api.service";

export const CREATE_PRODUCT = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("product", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const CREATE_STOCK = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("export-stock-count", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_ADJUSTMENT = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("adjustment", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UPDATE_PRODUCT = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`update-product/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// export const ValidateProduct = (type, params) => {
//   return new Promise((resolve, reject) => {
//     ApiService.setHeader();
//     ApiService.post(`validate/${type}/all-field`, params)
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch(({ response }) => {
//         reject(response);
//       });
//   });
// };

export const GET_PRODUCT = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`find-product/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_ADJUSTMENT = (currentPage, search) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query("adjustment", {
      current_page: currentPage,

      search,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const All_PRPDUCTS = (currentPage, selectstatus, budget_id, category_id, search) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`/list-product`, {
      current_page: currentPage,
      selectstatus,
      budget_id,
      category_id,
      search,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const All_PRPDUCTSS = (currentPage, perPage) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`/list-products`, {
      per_page: perPage,
      current_page: currentPage,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const All_PRPDUCTSSS = (currentPage, perPage) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`/list-product-adjustment`, {
      per_page: perPage,
      current_page: currentPage,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const STOCKEXCELUPLOAD = (data) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`/import-stock-count`, {
      data: data,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const IMPORTPRODUCT = (data) => {
  console.log(data, "data");
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`/import-products`, data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const IMPORTSUPPLIER = (data) => {
  console.log(data, "data");
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`/import-suppliers`, data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_SUPPLIER_PRODUCT = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("supplier-create-product", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
