<template>
  <v-sheet class="customer" id="product" style="height: calc(100vh - 135px)">
    <v-row>
      <v-col md="12" class="py-0">
        <!-- <v-row>
          <v-col md="8" class="my-0 pb-0">
            <v-layout>
              <v-flex class="my-auto">
                <h1 class="custom-header-blue-text m-0 text-truncate">
                  {{ pageTitle() }}
                </h1>
              </v-flex>
            </v-layout>
          </v-col>

          <v-col md="4" class="text-right pb-0">
            <v-btn
              :disabled="pageLoading"
              depressed
              tile
              class="mx-2 white--text"
              color="blue darken-4"
              :to="{
                name: 'product-update',
                params: { id: customerId },
                query: { t: new Date().getTime() },
              }"
            >
              Edit
            </v-btn>
            <v-btn
              :disabled="pageLoading"
              depressed
              tile
              class="mx-2 white--text"
              color="red lighten-1"
              v-on:click="deleteCustomerConfirm()"
            >
              Delete
            </v-btn>
            <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
              Back
            </v-btn>
          </v-col>
        </v-row> -->
      </v-col>
      <!-- left side -->
      <v-col md="3" class="pr-2">
        <v-card :elevation="1" style="height: calc(100vh - 97px); overflow-y: auto">
          <v-card-item class="d-flex justify-space-between align-center pt-2 px-2">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="pageLoading"
                  depressed
                  fab
                  x-small
                  tile
                  class="rounded-circle"
                  style="cursor: pointer"
                  @click="goBack()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="pa-1">mdi-arrow-left</v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
            <v-chip
              class="text-white rounded-lg d-flex justify-center mt-2"
              :color="product.status.color"
              v-if="products?.activated == 1"
            >
              Active
            </v-chip>
            <v-chip
              class="text-white rounded-lg d-flex justify-center mt-2"
              :color="product.status.color1"
              v-if="products?.activated == 0"
            >
              In-Active
            </v-chip>
          </v-card-item>

          <v-card-item class="d-flex justify-center pt-3">
            <ImageTemplate
              :src="products?.profile_logo?.file?.url"
              :maxWidth="150"
              :maxHeight="150"
              :aspectRatio="1"
              :contain="false"
              circle
            />
          </v-card-item>

          <v-card-item class="d-flex flex-column justify-center align-center">
            <v-card-title class="py-1 h4 mb-0 font-weight-bold">{{
              products?.name_en
            }}</v-card-title>
            <v-card-title class="py-1 h4 mb-0 font-weight-bold">{{
              products?.name_cn
            }}</v-card-title>
            <v-card-title class="py-1 h4 mb-0 font-weight-bold">{{
              products?.name_my
            }}</v-card-title>
          </v-card-item>

          <v-card-item>
            <h4 class="mt-3 text-center">
              ({{ products.unit_description_psu }} {{ products.primary_sales_uom }} X
              {{ products.unit_value }} in 1 {{ products.secondary_purchase_uom }} )
            </h4>
          </v-card-item>

          <!-- edit delete button -->
          <!-- <v-card-item class="d-flex align-center justify-center pb-3">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="pageLoading"
                  depressed
                  fab
                  x-small
                  tile
                  class="rounded-circle mx-2 white--text"
                  color="blue darken-4"
                  :to="{
                    name: 'product-update',
                    params: { id: customerId },
                    query: { t: new Date().getTime() },
                  }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="pa-1">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="pageLoading"
                  depressed
                  fab
                  x-small
                  tile
                  class="rounded-circle mx-2 white--text"
                  color="red lighten-1"
                  v-on:click="deleteCustomerConfirm()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="pa-1">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-card-item> -->

          <v-divider class="mt-0"></v-divider>

          <v-card-item class="d-flex flex-column align-center" style="overflow-y: auto">
            <v-row style="width: 100%">
              <v-col md="7" class="py-2 text-subtitle-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div :class="['flex-row', 'align-center']" v-bind="attrs" v-on="on">
                      <p :class="['text-truncate', 'h5', 'mb-0', 'font-weight-bold']">Code</p>
                    </div>
                  </template>

                  <span> </span>
                  <span>Code</span>
                </v-tooltip>
              </v-col>
              <v-col
                md="5"
                class="d-inline-block text-truncate py-1 font-weight-bold text-subtitle-1"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <p :class="['text-truncate', 'mb-0', 'h5']" v-bind="attrs" v-on="on">
                      {{ products.barcode }}
                    </p>
                  </template>
                  <p>{{ products.barcode }}</p>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row style="width: 100%">
              <v-col md="7" class="py-2 text-subtitle-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div :class="['flex-row', 'align-center']" v-bind="attrs" v-on="on">
                      <p :class="['text-truncate', 'h5', 'mb-0', 'font-weight-bold']">
                        Budget Category
                      </p>
                    </div>
                  </template>

                  <span> </span>
                  <span>Budget Category</span>
                </v-tooltip>
              </v-col>
              <v-col
                md="5"
                class="d-inline-block text-truncate py-1 font-weight-bold text-subtitle-1"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <p
                      :class="['text-truncate', 'mb-0', 'h5', 'text-capitalize']"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-if="products.otherCategory">
                        <span style="text-transform: uppercase"> {{ products.otherCategory }}</span>
                      </template>
                      <template v-else> No Budget Category </template>
                    </p>
                  </template>
                  <!-- <p> {{ products.category_name }}</p> -->
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row style="width: 100%">
              <v-col md="7" class="py-2 text-subtitle-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div :class="['flex-row', 'align-center']" v-bind="attrs" v-on="on">
                      <p :class="['text-truncate', 'h5', 'mb-0', 'font-weight-bold']">
                        Department Category
                      </p>
                    </div>
                  </template>

                  <span></span>
                  <span>Department Category</span>
                </v-tooltip>
              </v-col>
              <v-col
                md="5"
                class="d-inline-block text-truncate py-1 font-weight-bold text-subtitle-1"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <p
                      :class="['text-truncate', 'mb-0', 'h5', 'text-capitalize']"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ products.deptCategorys }}
                    </p>
                  </template>
                  <p>{{ products.deptCategorys }}</p>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row style="width: 100%">
              <v-col md="12" class="mt-3">
                <div class="h4 mb-0 font-weight-bold">UOM Details</div>
                <v-divider class="mb-0 mt-1"></v-divider>
              </v-col>

              <v-col md="7" class="py-2 text-subtitle-1 pr-0">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div :class="['d-flex', 'flex-row', 'align-center']" v-bind="attrs" v-on="on">
                      <v-chip
                        :class="['text-white', 'rounded-lg', 'd-flex', 'justify-center', 'mr-2']"
                        color="blue"
                        small
                      >
                        Primary
                      </v-chip>
                      <p :class="['text-truncate', 'h5', 'mb-0', 'font-weight-bold']">
                        (sales) UOM
                      </p>
                    </div>
                  </template>
                  <p>Primary</p>
                </v-tooltip>
              </v-col>

              <v-col
                md="5"
                class="d-inline-block text-truncate py-2 font-weight-bold text-subtitle-1 my-auto ps-0"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <p :class="['text-truncate', 'mb-0', 'h5']" v-bind="attrs" v-on="on">
                      {{ products.primary_sales_uom }}({{ products.primary_sales_uom }})
                    </p>
                  </template>
                  <p>{{ products.primary_sales_uom }}({{ products.primary_sales_uom }})</p>
                </v-tooltip>
              </v-col>
              <v-col md="7" class="py-2 text-subtitle-1 pr-0">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div :class="['d-flex', 'flex-row', 'align-center']" v-bind="attrs" v-on="on">
                      <v-chip
                        :class="['text-white', 'rounded-lg', 'd-flex', 'justify-center', 'mr-2']"
                        color="red"
                        small
                      >
                        Secondary
                      </v-chip>
                      <p :class="['text-truncate', 'h5', 'mb-0', 'font-weight-bold']">
                        (purchase) UOM
                      </p>
                    </div>
                  </template>
                  <p>Secondary</p>
                </v-tooltip>
              </v-col>

              <v-col
                md="5"
                class="d-inline-block text-truncate py-2 font-weight-bold text-subtitle-1 my-auto ps-0"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <p :class="['text-truncate', 'mb-0', 'h5']" v-bind="attrs" v-on="on">
                      {{ products.secondary_purchase_uom }}({{ products.secondary_purchase_uom }})
                    </p>
                  </template>
                  <p>
                    {{ products.secondary_purchase_uom }}({{ products.secondary_purchase_uom }})
                  </p>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-item>

          <!-- <v-card-item>
            <v-card-actions class="d-flex flex-row justify-center mt-3">
              <v-btn
                :disabled="pageLoading"
                depressed
                tile
                class="mx-2 white--text text-subtitle-1 px-0"
                color="blue darken-4"
                :to="{
                  name: 'product-update',
                  params: { id: customerId },
                  query: { t: new Date().getTime() },
                }"
              >
                Edit
              </v-btn>
            </v-card-actions>
          </v-card-item> -->
        </v-card>
      </v-col>

      <v-col md="9" class="pl-2" color="blue darken-4">
        <v-card>
          <v-card-item id="product-tabs" class="d-flex flex-row justify-space-between">
            <v-tabs
              fixed-tabs
              v-model="productTab"
              color="blue darken-4"
              background-color="transparent"
              centered
            >
              <v-tab
                v-for="(tab, index) in product_tabs"
                :key="`product_${tab.key}_${index}`"
                :href="`#${tab.key}`"
                class="py-2"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      {{ tab.title }}
                    </span>
                  </template>
                  <span>{{ tab.tooltip }}</span>
                </v-tooltip>
              </v-tab>
            </v-tabs>
            <div class="d-flex align-center justify-space-between" style="width: 210px">
              <v-btn
                depressed
                tile
                color="blue darken-4"
                class="white--text"
                small
                :to="{
                  name: 'product-update',
                  params: { id: customerId },
                  query: { t: new Date().getTime() },
                }"
              >
                <v-icon left color="white">mdi-pencil</v-icon>
                <span style="font-size: 14px">Edit</span>
              </v-btn>
              <v-menu offset-y left :close-on-content-click="false">
                <!-- <template v-slot:activator="{ on, attrs }">
                  <v-btn class="white--text" :ripple="false" tile @click.stop v-bind="attrs" v-on="on"
                    color="blue darken-4" small>
                    <v-icon left color="white">mdi-more</v-icon>
                    <span style="font-size: 14px">More</span>
                  </v-btn>
                </template> -->
                <v-list>
                  <v-list-item
                    v-for="item in more_options"
                    @click="moreOptionsClick(item.key)"
                    :key="item.key"
                  >
                    <v-list-item-title>
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-card-item>

          <v-card-item>
            <v-tabs-items
              v-model="productTab"
              style="height: calc(100vh - 134px); overflow-y: auto"
            >
              <template v-for="(tab, index) in product_tabs">
                <v-tab-item :value="tab.key" :key="tab.key + '_' + index">
                  <component :is="tab.template" :parentdetails="sutra" />
                </v-tab-item>
              </template>
            </v-tabs-items>
          </v-card-item>
        </v-card>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { toSafeInteger } from "lodash";
import TextInput from "@/view/components/TextInput";
import Chip from "@/view/components/Chip";
import ValueTemplate from "@/view/components/ValueTemplate";

import AvailableTemplate from "@/view/components/AvailableVoucherTemplate";
import RedeemedTemplate from "@/view/components/RedeemedVoucherTemplate";
import ExpiredTemplate from "@/view/components/ExpiredVoucherTemplate";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_CUSTOMER } from "@/core/lib/customer.lib";
import { GET_ORDERS } from "@/core/lib/order.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import {
  COMMON_EVENT_BUS,
  GET_AVAILABLE_VOUCHERS,
  GET_COLLECTED_VOUCHERS,
} from "@/core/lib/common.lib";
import { format } from "date-fns";

import ImageTemplate from "@/view/components/Image";
// Product Details tab components
import StockDetails from "./Stock-Details.vue";
import Supplier from "./Supplier.vue";
import ProductPurchases from "./Product-Purchases.vue";
import Stocktransfer from "./Stock-transfer.vue";

import ProductQualityAdjustments from "./Product-Quantity-Adjustments.vue";
import Logs from "./Logs.vue";

import TooltipQuestion from "@/view/components/TooltipQuestion";
import { GET_PRODUCT } from "@/core/lib/product.lib";

export default {
  name: "customer-detail",
  title: "Detail Customer",
  data() {
    return {
      speedDial: [],
      products: {},
      cLoading: false,
      sutra: null,
      rLoading: false,
      cDeleteText: null,
      cDeleteDialog: false,
      cDeleteEndpoint: null,
      deleteDialog: false,
      deleteEndpoint: null,
      deleteText: null,
      pageLoading: true,
      orderDialog: false,
      collectDialog: false,
      redeemDialog: false,
      customerId: null,
      customerTab: "orders",
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      search: null,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },

      customer: {
        barcode: null,
        title: null,
        first_name: null,
        last_name: null,
        full_name: null,
        email: null,
        date_of_birth: null,
        phone_number: null,
        address_line_1: null,
        address_line_2: null,
        unit_number: null,
        postal_code: null,
        country: null,
        total_orders: null,
        total_vcollected: null,
        total_vredeemed: null,
        total_vexpired: null,
        added_by: null,
        added_at: null,
        updated_by: null,
        updated_at: null,
      },

      orders: [],
      cVouchers: [],
      cOrderId: 0,
      cOrderCode: null,
      rOrderId: 0,
      rVouchers: [],
      rOrderCode: null,

      product_tabs: [
        {
          key: "stock-details",
          title: "Stock",
          tooltip: "Stock",
          template: StockDetails,
        },
        {
          key: "supplier",
          title: "Supplier",
          tooltip: "Supplier",
          template: Supplier,
        },
        {
          key: "quantity-adjustments",
          title: "Qty Adj",
          tooltip: "Quantity Adjustment",
          template: ProductQualityAdjustments,
        },
        {
          key: "po",
          title: "P.O.",
          tooltip: "Purchase Order",
          template: ProductPurchases,
        },
        {
          key: "transfer",
          title: "Transfer",
          tooltip: "Stock Transfer",
          template: Stocktransfer,
        },
        {
          key: "logs",
          title: "Logs",
          tooltip: "Logs",
          template: Logs,
        },
      ],

      product_details: [
        {
          name: "(sales) UOM",
          value: "BTL (BTL)",
          type: { name: "chip", text: "Primary", color: "blue" },
        },
        {
          name: "Unit Description",
          value: "12 300ml CAN X CTN",
        },
        {
          name: "(purchase) UOM",
          value: "CTN (CTN)",
          type: { name: "chip", text: "Secondary", color: "red" },
        },
      ],

      more_options: [
        { title: "Add Adjustment", key: "add_adjustment" },
        { title: "Add PO", key: "add_po" },
        { title: "Delete / Inactive", key: "delete_inactive" },
      ],

      product: {
        name: "Pineapple juice",
        status: { name: "Active", color: "green", color1: "red" },
      },
    };
  },
  watch: {
    currentPage() {
      this.getOrders();
    },
  },

  computed: {
    productTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        query.t = new Date().getTime();
        if (val != this.productTab) {
          this.$router.replace({ query });
        }
      },
      get() {
        return this.$route.query.tab || "product-details";
      },
    },
  },

  methods: {
    async getProductData() {
      this.product_id = this.$route.params.id;
      console.log(this.product_id);

      if (this.product_id) {
        try {
          const product = await GET_PRODUCT(this.product_id);
          // _this.getOrders();
          this.products = product.rows;
          this.sutra = product;
          //      this.product.suppliers = product.rows.supplier;
          console.log(this.sutra);

          // " this.product.warehouse.sutra= product.sutra
          //  this.product.warehouse.sutra.primary_quantity= product.sutra.primary_qty;
          //  this.product.warehouse.sutra.secondary_quantity= product.sutra.secondary_qty;
          //  this.product.warehouse.nusa= product.nusa
          //   this.product.warehouse.nusa.primary_quantity= product.nusa.primary_qty;
          //   this.product.warehouse.nusa.secondary_quantity= product.nusa.secondary_qty;
          // "
        } catch (error) {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        } finally {
          this.pageLoading = false;
        }
      }
    },
    moreOptionsClick(key) {
      switch (key) {
        case "edit":
          this.$router.push({
            name: "product-update",
            params: { id: this.customerId },
            query: { t: new Date().getTime() },
          });
          break;
        case "add_po":
          console.log("Add PO option clicked");
          break;
        case "add_adjustment":
          console.log("Add Adjustment option clicked");
          break;
        case "delete_inactive":
          console.log("Delete Inactive option clicked");
          break;
      }
    },
    async getAvailableVouchers(orderId, barcode) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = barcode;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    async getCollectedVouchers(orderId, barcode) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = barcode || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.customerId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.getOrders();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.getOrders();
    },
    deleteCustomerConfirm() {
      this.cDeleteText = this.customer.barcode;
      this.cDeleteDialog = true;
      this.cDeleteEndpoint = `customer/${this.customerId}`;
    },
    deleteConfirm(row) {
      this.deleteDialog = true;
      this.deleteText = row.barcode;
      this.deleteEndpoint = `customer/${this.customerId}/order/${row.id}`;
    },
    clearVars() {
      this.orderDialog = false;
      this.deleteDialog = false;
      this.deleteText = null;
      this.deleteEndpoint = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    pageTitle() {
      if (this.customer.full_name) {
        return this.customer.full_name;
      }
      return "Detail Product";
    },
    routeToUpdate(row) {
      this.order = {
        id: row.id,
        date: row.date,
        time: row.time,
        bill_no: row.bill_no,
        amount: row.amount,
        voucher: row.voucher,
      };
      this.orderDialog = true;
    },
    async getCustomer() {
      const _this = this;
      try {
        const customer = await GET_CUSTOMER(_this.customerId);
        _this.getOrders();
        _this.customer = customer;
        _this.$store.dispatch(SET_BREADCRUMB, [
          { text: "Customer", disabled: true },
          { text: "Detail", disabled: true },
          { text: customer.barcode, disabled: true },
        ]);
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    async getOrders() {
      const _this = this;
      const form = {
        current_page: _this.currentPage,
        search: _this.search,
      };
      try {
        _this.pageLoading = true;
        const { rows, totalPages, showingFrom, showingTo, totalRows } = await GET_ORDERS(
          _this.customerId,
          form
        );
        _this.clearVars();
        _this.orders = rows;
        _this.pageLimit = totalPages;
        _this.showingFrom = showingFrom;
        _this.showingTo = showingTo;
        _this.totalRows = totalRows;
        COMMON_EVENT_BUS.$emit("orders:loaded", true);
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Customer", disabled: true },
      { text: "Detail", disabled: true },
    ]);

    const { id } = this.$route.params;

    if (id) {
      this.customerId = toSafeInteger(id);
      this.getProductData();
    } else {
      this.goBack();
    }

    if (this.$route.query && this.$route.query.tab) {
      this.settingTab = this.$route.query.tab;
    } else {
      this.$router.replace({ query: { tab: "product-details", t: new Date().getTime() } });
    }
  },
  components: {
    Chip,
    TextInput,
    ValueTemplate,
    AvailableTemplate,
    RedeemedTemplate,
    ExpiredTemplate,
    ImageTemplate,
    TooltipQuestion,
  },
};
</script>

<style scoped>
#product-tabs .v-slide-group__content.v-tabs-bar__content {
  display: flex !important;
  justify-content: space-between !important;
}
</style>
