<template>
  <v-container fluid class="pt-0">
    <v-row class="vouchers pt-6">
      <v-col md="12" v-if="loading" class="text-center p-10">
        <v-progress-linear
          indeterminate
          height="6"
          rounded
          color="blue darken-4"
        ></v-progress-linear>
      </v-col>
      <template v-else-if="vouchers.length">
        <v-col md="4" v-for="(row, index) in vouchers" class="p-3" :key="index">
          <div class="border-light-grey p-4">
            <v-layout>
              <v-flex md4>
                <ImageTemplate :src="row.image"></ImageTemplate>
              </v-flex>
              <v-flex md8 class="ml-4 my-auto">
                <v-chip
                  v-if="!row.redeemed_order"
                  :disabled="oloading"
                  v-on:click="getUnlinkedOrders(row)"
                  class="redeemed-voucher-link blue darken-4 px-3 py-0 max-height-20px"
                  text-color="white"
                >
                  Link
                </v-chip>
                <p class="m-0 v-name pb-2 black--text">{{ row.name }}</p>
                <p class="m-0 v-expiry" v-if="row.expiry_date">
                  Expires: {{ formatDate(row.expiry_date) }}
                </p>
                <p class="m-0 v-code pt-6 text-center">{{ row.vcode }}</p>
                <p class="m-0 pb-6 pt-2 v-coupon text-center">COUPON</p>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md12>
                <span class="float-left">
                  <v-chip class="ma-2" color="deep-orange darken-4" text-color="white">
                    Redeemed
                  </v-chip>
                </span>
                <span class="float-right">
                  <v-menu :close-on-content-click="false" :nudge-width="200" offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip class="ma-2" v-bind="attrs" v-on="on"> Terms of use </v-chip>
                    </template>

                    <v-card flat max-width="400px" rounded="0">
                      <v-card-text flat rounded="0">
                        <label class="btx-label"> TERMS OF USE</label>
                        <p class="light-border-top py-2">{{ row.description }}</p>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </span>
              </v-flex>
            </v-layout>
          </div>
        </v-col>
      </template>
      <v-col v-else md="12" class="text-center">
        <p class="m-0 text-center black--text text-h5">
          <img
            width="50"
            :src="$assetURL('media/error/empty.png')"
            class="row-not-found-image mr-4"
          />
          Uhh... There are no voucher available at the moment.
        </p>
      </v-col>
    </v-row>
    <Dialog dense :dialog="dialog">
      <template v-slot:title> Link Voucher | {{ vtitle }} </template>
      <template v-slot:body>
        <v-container fluid>
          <v-row>
            <template v-if="orders.length">
              <v-col v-for="(order, index) in orders" :key="index" md="12">
                <table
                  v-on:click="selectOrder(order)"
                  width="100%"
                  class="detail-table border-bold-grey"
                  :class="{ 'selected-v-order': order.id == orderId }"
                >
                  <tr>
                    <th width="300">Order #</th>
                    <td width="300">{{ order.barcode }}</td>
                    <th width="300">POS Bill #</th>
                    <td width="300">{{ order.bill_no }}</td>
                  </tr>
                  <tr>
                    <th width="300">Date Time</th>
                    <td width="300">{{ formatDateTime(order.datetime) }}</td>
                    <th width="300">Amount</th>
                    <td width="300">{{ formatCurrency(order.amount) }}</td>
                  </tr>
                </table>
              </v-col>
            </template>
            <v-col v-else md="12">
              <p class="m-0 text-center black--text text-h6">
                <img
                  width="50"
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image mr-4"
                />
                Uhh... There are no unlinked orders at the moment.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="loading || oloading"
          :loading="loading || oloading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          v-on:click="saveOrder()"
        >
          Save
        </v-btn>
        <v-btn :disabled="loading" depressed tile v-on:click="dialog = false"> Cancel </v-btn>
      </template>
    </Dialog>
  </v-container>
</template>
<script>
import ImageTemplate from "@/view/components/Image";
import Dialog from "@/view/components/Dialog";
import { SET_ERROR } from "@/core/services/store/common.module";
import { COMMON_EVENT_BUS, LINK_ORDER_VOUCHER } from "@/core/lib/common.lib";
import { GET_REDEEMED_VOUCHERS, GET_UNLINKED_ORDERS } from "@/core/lib/customer.lib";
export default {
  props: {
    customerId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      oloading: false,
      dialog: false,
      loading: true,
      vtitle: null,
      vcid: null,
      orderId: null,
      orders: [],
      vouchers: [],
    };
  },
  components: {
    Dialog,
    ImageTemplate,
  },
  methods: {
    selectOrder({ id }) {
      this.orderId = id;
    },
    async saveOrder() {
      try {
        this.oloading = true;
        await LINK_ORDER_VOUCHER(this.vcid, this.orderId);
        this.clearVars();
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.oloading = false;
      }
    },
    clearVars() {
      this.oloading = false;
      this.vtitle = null;
      this.vcid = null;
      this.orderId = null;
      this.orders = [];
      this.dialog = false;
      this.getRedeemedVouchers();
    },
    async getUnlinkedOrders({ vcode, vcid }) {
      try {
        this.oloading = true;
        this.vtitle = vcode;
        this.vcid = vcid;
        this.orders = await GET_UNLINKED_ORDERS(this.customerId);
        this.dialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.oloading = false;
      }
    },
    async getRedeemedVouchers() {
      try {
        this.vouchers = await GET_REDEEMED_VOUCHERS(this.customerId);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    const _this = this;

    _this.getRedeemedVouchers();

    COMMON_EVENT_BUS.$on("orders:loaded", function () {
      _this.clearVars();
    });
  },
};
</script>
