<template>
  <v-container fluid class="pt-0">
    <pre></pre>
    <v-row class="vouchers pt-6">
      <v-col md="12" v-if="loading" class="text-center p-10">
        <v-progress-linear
          indeterminate
          height="6"
          rounded
          color="blue darken-4"
        ></v-progress-linear>
      </v-col>
      <template v-else-if="Products.length">
        <v-simple-table fixed-header class="bt-table table_height_sales w-100">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="pa-2" width="20%">NAME</th>
                <th class="pa-2" width="20%">Product#</th>
                <th class="pa-2" width="20%">Image</th>
                <th class="pa-2" width="20%">PRIMARY</th>
                <th class="pa-2" width="20%">SECONDARY</th>
              </tr>
            </thead>
            <tbody v-if="!pageLoading">
              <tr v-for="(row, index) in Products" :key="index">
                <td class="simple-table-td text-capitalize px-2">{{ row.name_en }}.</td>
                <td class="simple-table-td px-2">{{ row.barcode }}</td>

                <td class="simple-table-td px-2">
                  <div>
                    <v-avatar size="35px">
                      <img
                        v-if="row?.profile_logo?.file?.url"
                        max-height="40"
                        max-width="40"
                        :src="row?.profile_logo?.file?.url"
                        alt="John"
                      />
                      <img
                        v-else
                        max-height="40"
                        max-width="40"
                        src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/files/image/png/OFVhotIVFLGfIcidqwYD9YQxR3edOtSq7cZaTji6.png"
                        alt="John"
                      />
                    </v-avatar>
                  </div>
                </td>
                <td class="simple-table-td px-4">
                  {{ row?.singlewarehouse.primary_qty }} {{ row?.singlewarehouse.primary_uom }}
                </td>
                <td class="simple-table-td px-4">
                  {{ row?.singlewarehouse.secondary_qty }} {{ row?.singlewarehouse.secondary_uom }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
      <v-col v-else md="12" class="text-center">
        <p class="m-0 text-center black--text text-h5">
          <img
            width="50"
            :src="$assetURL('media/error/empty.png')"
            class="row-not-found-image mr-4"
          />
          Uhh... There are no products available at the moment.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { SET_ERROR } from "@/core/services/store/common.module";
import { COMMON_EVENT_BUS } from "@/core/lib/common.lib";
export default {
  props: {
    products: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      loading: true,
      vouchers: [],
      Products: [],
    };
  },
  watch: {
    products: {
      deep: true,
      immediate: true,
      handler(param) {
        console.log(param, "persons");
        this.Products = param;
      },
    },
  },
  methods: {
    getExpiredVouchers() {
      try {
        this.vouchers = null;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    const _this = this;

    _this.getExpiredVouchers();

    COMMON_EVENT_BUS.$on("orders:loaded", function () {
      _this.getExpiredVouchers();
    });
  },
};
</script>
<style scoped>
tr:nth-child {
  background-color: #fff;
}
</style>
