<template>
  <!-- <h5 class="mb-0 px-4 h4 font-weight-bold py-3">Stock</h5>
    <v-divider class="my-0"></v-divider> -->
  <v-row style="margin: 0% !important" class="pt-3 pb-2 px-4">
    <!-- <pre>{{ parentdetails.rows.supplier.initial_primary_price }}</pre> -->
    <!-- <v-col md="12" class="px-2"> -->
    <!-- <v-row> -->
    <v-col md="6" class="px-0 border-light-grey">
      <p class="mb-0 h5 px-3 font-weight-bold">Total stock</p>
      <v-divider></v-divider>
      <v-row class="px-3" style="margin: 0% !important">
        <v-col md="12">
          <div class="d-flex mb-3 justify-space-between align-center" style="width: 25vw">
            <v-chip
              style="width: 150px"
              class="ml-2 px-0 text-white rounded-lg d-flex justify-center"
              color="blue"
            >
              Primary
            </v-chip>
            <!-- <p class="font-weight-bold h3 mb-0">&nbsp;:&nbsp;</p> -->
            <p class="h5 mb-0" style="width: 150px">
              <template
                v-if="parentdetails && parentdetails?.sutra && parentdetails?.sutra?.primary_qty"
                >{{ Number(parentdetails?.sutra?.primary_qty) }}
                {{ parentdetails?.sutra?.primary_uom }}</template
              >
              <template v-else>0 {{ parentdetails?.sutra?.primary_uom }}</template>
            </p>
          </div>
          <div class="d-flex my-3 justify-space-between align-center" style="width: 25vw">
            <v-chip
              style="width: 150px"
              class="ml-2 px-0 text-white rounded-lg d-flex justify-center"
              color="red"
            >
              Secondary
            </v-chip>
            <!-- <p class="font-weight-bold h3 mb-0">&nbsp;:&nbsp;</p> -->
            <p class="h5 mb-0" style="width: 150px">
              <template
                v-if="parentdetails && parentdetails?.sutra && parentdetails?.sutra?.secondary_qty"
                >{{ Number(parentdetails?.sutra?.secondary_qty) }}
                {{ parentdetails?.sutra?.secondary_uom }}</template
              >
              <template v-else>0 {{ parentdetails?.sutra?.secondary_uom }}</template>
            </p>
          </div>
          <div class="d-flex mt-3 align-center justify-space-between" style="width: 25vw">
            <p style="width: 150px" class="h5 font-weight-bold ml-2 mb-0">Total Quantity</p>
            <!-- <span class="font-weight-bold h3 mb-0">&nbsp;:&nbsp;</span> -->
            <p class="h5 mb-0 font-weight-bold" style="width: 150px">
              <template v-if="parentdetails && parentdetails?.sutra"
                >{{
                  parentdetails?.sutra?.secondary_qty * parentdetails?.rows?.unit_value +
                  Number(parentdetails?.sutra?.primary_qty)
                }}
                {{ parentdetails?.sutra?.primary_uom }}</template
              >
            </p>
          </div>
          <div class="d-flex mt-3 align-center justify-space-between" style="width: 25vw">
            <p style="width: 150px" class="h5 font-weight-bold ml-2 mb-0">Total Stock Price</p>
            <!-- <span class="font-weight-bold h3 mb-0">&nbsp;:&nbsp;</span> -->
            <p
              class="h5 mb-0 font-weight-bold"
              v-if="parentdetails?.final_amount"
              style="width: 150px"
            >
              <template v-if="parentdetails && parentdetails?.sutra">{{
                formatMoney(
                  (parentdetails.sutra?.secondary_qty * parentdetails?.rows?.unit_value +
                    Number(parentdetails?.sutra?.primary_qty)) *
                    parentdetails?.final_amount
                )
              }}</template>
            </p>
            <p class="h5 mb-0 font-weight-bold" style="width: 150px" v-else>
              <template v-if="parentdetails && parentdetails?.sutra">
                {{
                  formatMoney(
                    (parentdetails?.sutra?.secondary_qty * parentdetails?.rows?.unit_value +
                      Number(parentdetails?.sutra?.primary_qty)) *
                      parentdetails.initial_price
                  )
                }}</template
              >
            </p>
          </div>
          <div class="d-flex mt-3 align-center justify-space-between" style="width: 25vw">
            <p style="width: 150px" class="h5 font-weight-bold ml-2 mb-0">Per Stock Price</p>
            <!-- <span class="font-weight-bold h3 mb-0">&nbsp;:&nbsp;</span> -->
            <p
              class="h5 mb-0 font-weight-bold"
              v-if="parentdetails?.final_amount"
              style="width: 150px"
            >
              {{ formatMoney(parentdetails?.final_amount) }} /
              {{ parentdetails?.sutra?.primary_uom }}
            </p>
            <p class="h5 mb-0 font-weight-bold" v-else style="width: 150px">
              {{ formatMoney(parentdetails?.initial_price) }} /
              {{ parentdetails?.sutra?.primary_uom }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col md="6 " class="px-0 border-light-grey">
      <p class="mb-0 h5 px-3 font-weight-bold">Initial stock</p>
      <v-divider></v-divider>
      <v-row class="px-3" style="margin: 0% !important">
        <v-col md="12">
          <div class="d-flex mb-3 justify-space-between align-center" style="width: 25vw">
            <v-chip
              style="width: 150px"
              class="ml-2 px-0 text-white rounded-lg d-flex justify-center"
              color="blue"
            >
              Primary
            </v-chip>
            <!-- <p class="font-weight-bold h3 mb-0">&nbsp;:&nbsp;</p> -->
            <p class="h5 mb-0" style="width: 150px">
              <template
                v-if="
                  parentdetails && parentdetails?.sutra && parentdetails?.sutra?.initial_primary
                "
              ></template
              >{{ Number(parentdetails?.sutra?.initial_primary) }}
              <template
                v-if="parentdetails && parentdetails?.sutra && parentdetails?.sutra?.primary_uom"
                >{{ parentdetails?.sutra?.primary_uom }}</template
              >
            </p>
          </div>
          <div class="d-flex my-3 justify-space-between align-center" style="width: 25vw">
            <v-chip
              style="width: 150px"
              class="ml-2 px-0 text-white rounded-lg d-flex justify-center"
              color="red"
            >
              Secondary
            </v-chip>
            <!-- <p class="font-weight-bold h3 mb-0">&nbsp;:&nbsp;</p> -->
            <p class="h5 mb-0" style="width: 150px">
              <template
                v-if="
                  parentdetails && parentdetails?.sutra && parentdetails?.sutra?.initial_secondary
                "
              >
                {{ Number(parentdetails?.sutra?.initial_secondary) }}</template
              >

              <template v-if="parentdetails && parentdetails?.sutra?.secondary_uom">{{
                parentdetails?.sutra?.secondary_uom
              }}</template>
            </p>
          </div>
          <div class="d-flex mt-3 align-center justify-space-between" style="width: 25vw">
            <p style="width: 150px" class="h5 font-weight-bold ml-2 mb-0">Total Quantity</p>
            <!-- <span class="font-weight-bold h3 mb-0">&nbsp;:&nbsp;</span> -->
            <p class="h5 mb-0 font-weight-bold" style="width: 150px">
              <template v-if="parentdetails && parentdetails?.sutra"></template>
              {{
                parentdetails?.sutra?.initial_secondary * parentdetails?.rows?.unit_value +
                Number(parentdetails?.sutra?.initial_primary)
              }}
              {{ parentdetails?.sutra?.primary_uom }}
            </p>
          </div>
          <div class="d-flex mt-3 align-center justify-space-between" style="width: 25vw">
            <p style="width: 150px" class="h5 font-weight-bold ml-2 mb-0">Total Stock Price</p>
            <!-- <span class="font-weight-bold h3 mb-0">&nbsp;:&nbsp;</span> -->
            <p class="h5 mb-0 font-weight-bold" style="width: 150px">
              <template
                v-if="
                  parentdetails && parentdetails?.sutra && parentdetails?.sutra?.initial_secondary
                "
                >{{
                  formatMoney(
                    (parentdetails?.sutra?.initial_secondary * parentdetails?.rows?.unit_value +
                      Number(parentdetails?.sutra?.initial_primary)) *
                      parentdetails?.initial_primary_price
                  )
                }}</template
              >
              <template v-else> RM 0.00</template>
            </p>
          </div>
          <div class="d-flex mt-3 align-center justify-space-between" style="width: 25vw">
            <p style="width: 150px" class="h5 font-weight-bold ml-2 mb-0">Per Stock Price</p>
            <!-- <span class="font-weight-bold h3 mb-0">&nbsp;:&nbsp;</span> -->
            <p class="h5 mb-0 font-weight-bold" style="width: 150px">
              <template v-if="parentdetails && parentdetails?.initial_primary_price"
                >{{ formatMoney(parentdetails?.initial_primary_price) }} /
                {{ parentdetails?.sutra?.primary_uom }}</template
              >
            </p>
          </div>
        </v-col>
      </v-row>
    </v-col>

    <!-- Warehouse Quantity -->
    <!-- <v-col md="12" class="px-0 d-flex justify-space-between">
      <v-row
        style="margin: 0% !important; margin-top: 12px !important; margin-right: 12px !important"
        class="border-light-grey"
      >
        <v-col md="12" class="grey lighten-4">
          <p class="mb-0 h5 font-weight-bold">Sutra</p>
        </v-col>
        <v-col md="12" class="pa-0 grey lighten-4">
          <v-divider class="mt-0"></v-divider>
        </v-col>
        <v-col md="12" class="pt-0 grey lighten-4">
          <v-row style="margin: 0% !important">
            <v-col md="12">
              <div class="d-flex mb-3 align-center justify-space-between" style="width: 15vw">
                <v-chip
                  style="width: 100px"
                  class="ml-2 px-0 text-white rounded-lg d-flex justify-center"
                  color="blue"
                >
                  Primary
                </v-chip>
                <p class="h5 mb-0">
                  {{ parentdetails.sutra.primary_qty }} {{ parentdetails.sutra.primary_uom }}
                </p>
              </div>
              <div class="d-flex my-3 align-center justify-space-between" style="width: 15vw">
                <v-chip
                  style="width: 100px"
                  class="ml-2 px-0 text-white rounded-lg d-flex justify-center"
                  color="red"
                >
                  Secondary
                </v-chip>
                <p class="h5 mb-0">
                  {{ parentdetails.sutra.secondary_qty }} {{ parentdetails.sutra.secondary_uom }}
                </p>
              </div>
              <div class="d-flex mt-3 align-center justify-space-between" style="width: 15vw">
                <p style="width: 130px" class="h5 font-weight-bold ml-2 mb-0">Total Quantity</p>
                <span class="font-weight-bold h3 mb-0">&nbsp;:&nbsp;</span>
                <p class="h5 mb-0 font-weight-bold">
                  {{
                    parentdetails.sutra.secondary_qty * parentdetails.rows.unit_value +
                    Number(parentdetails.sutra.primary_qty)
                  }}
                  {{ parentdetails.sutra.primary_uom }}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row
        style="margin: 0% !important; margin-top: 12px !important; margin-left: 12px !important"
        class="border-light-grey"
      >
        <v-col md="12" class="grey lighten-4">
          <p class="mb-0 h5 font-weight-bold">Nusa</p>
        </v-col>
        <v-col md="12" class="pa-0 grey lighten-4">
          <v-divider class="mt-0"></v-divider>
        </v-col>
        <v-col md="12" class="pt-0 grey lighten-4">
          <v-row style="margin: 0% !important">
            <v-col md="12">
              <div class="d-flex mb-3 align-center justify-space-between" style="width: 15vw">
                <v-chip
                  style="width: 100px"
                  class="ml-2 px-0 text-white rounded-lg d-flex justify-center"
                  color="blue"
                >
                  Primary
                </v-chip>
                <p class="h5 mb-0">
                  {{ parentdetails.nusa.primary_qty }} {{ parentdetails.sutra.primary_uom }}
                </p>
              </div>
              <div class="d-flex my-3 align-center justify-space-between" style="width: 15vw">
                <v-chip
                  style="width: 100px"
                  class="ml-2 px-0 text-white rounded-lg d-flex justify-center"
                  color="red"
                >
                  Secondary
                </v-chip>
                <p class="h5 mb-0">
                  {{ parentdetails.nusa.secondary_qty }} {{ parentdetails.nusa.secondary_uom }}
                </p>
              </div>
              <div class="d-flex mt-3 align-center justify-space-between" style="width: 15vw">
                <p style="width: 130px" class="h5 font-weight-bold ml-2 mb-0">Total Quantity</p>
                <span class="font-weight-bold h3 mb-0">&nbsp;:&nbsp;</span>
                <p class="h5 mb-0 font-weight-bold">
                  {{
                    parentdetails.nusa.secondary_qty * parentdetails.rows.unit_value +
                    Number(parentdetails.nusa.primary_qty)
                  }}
                  {{ parentdetails.nusa.primary_uom }}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col> -->
  </v-row>
</template>

<script>
import { formatMoney } from "accounting-js";

export default {
  props: {
    parentdetails: {
      type: [Object, Array],
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
  },
};
</script>

<style scoped>
.th {
  font-size: 13px !important;
}

.product-row {
  margin: 0% !important;
}
</style>
