<template>
  <v-chip :class="customClass" :color="color" label outlined :small="small">
    {{ text }}
  </v-chip>
</template>

<script>
export default {
  name: "btx-chip",
  props: {
    color: {
      type: String,
      default: "blue",
    },
    text: {
      type: [String, Number],
      default: null,
    },
    customClass: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
