<template>
  <v-container fluid class="pt-0">
    <v-row class="vouchers pt-6">
      <v-col md="12" v-if="loading" class="text-center p-10">
        <v-progress-linear
          indeterminate
          height="6"
          rounded
          color="blue darken-4"
        ></v-progress-linear>
      </v-col>
      <template v-else-if="vouchers.length">
        <v-col md="4" v-for="(row, index) in vouchers" class="p-3" :key="index">
          <div class="border-light-grey p-4">
            <v-layout>
              <v-flex md4>
                <ImageTemplate :src="row.image"></ImageTemplate>
              </v-flex>
              <v-flex md8 class="ml-4 my-auto">
                <p class="m-0 v-name pb-2 black--text">{{ row.name }}</p>
                <p class="m-0 v-expiry" v-if="row.expiry_date">
                  Expires: {{ formatDate(row.expiry_date) }}
                </p>
                <p class="m-0 v-code pt-6 text-center">{{ row.vcode }}</p>
                <p class="m-0 pb-6 pt-2 v-coupon text-center">COUPON</p>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md12>
                <span class="float-left">
                  <v-chip class="ma-2" color="green" text-color="white"> Collected </v-chip>
                </span>
                <span class="float-right">
                  <v-menu :close-on-content-click="false" :nudge-width="200" offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip class="ma-2" v-bind="attrs" v-on="on"> Terms of use </v-chip>
                    </template>

                    <v-card flat max-width="400px" rounded="0">
                      <v-card-text flat rounded="0">
                        <label class="btx-label"> TERMS OF USE</label>
                        <p class="light-border-top py-2">{{ row.description }}</p>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </span>
              </v-flex>
            </v-layout>
          </div>
        </v-col>
      </template>
      <v-col v-else md="12" class="text-center">
        <p class="m-0 text-center black--text text-h5">
          <img
            width="50"
            :src="$assetURL('media/error/empty.png')"
            class="row-not-found-image mr-4"
          />
          Uhh... There are no voucher available at the moment.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ImageTemplate from "@/view/components/Image";
import { SET_ERROR } from "@/core/services/store/common.module";
import { GET_AVAILABLE_VOUCHERS } from "@/core/lib/customer.lib";
import { COMMON_EVENT_BUS } from "@/core/lib/common.lib";
export default {
  props: {
    customerId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: true,
      vouchers: [],
    };
  },
  components: {
    ImageTemplate,
  },
  methods: {
    async getAvailableVouchers() {
      try {
        this.vouchers = await GET_AVAILABLE_VOUCHERS(this.customerId);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    const _this = this;

    _this.getAvailableVouchers();

    COMMON_EVENT_BUS.$on("orders:loaded", function () {
      _this.getAvailableVouchers();
    });
  },
};
</script>
